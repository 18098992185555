.deal_closed_lost_container {
  display: flex;
  justify-content: space-evenly;
}

.hide_button {
  display: none;
}

.button_closed {
  border: 0;
  outline: 0;
  cursor: pointer;
  color: white;
  background-color: #2ea44f;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, #2ea44f 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 8px;
  display: inline-block;
  min-height: 28px;
  transition: background-color .24s, box-shadow .24s;
}

.button_closed:hover {
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, #2ea44f 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 3px 9px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
}

.button_closed:disabled{
   background:  #fff;
   color: #ccc;
   cursor: no-drop;
}


.button_lost {
  border: 0;
  outline: 0;
  cursor: pointer;
  color: white;
  background-color: #cc4d29;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, #cc4d29 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 8px;
  display: inline-block;
  min-height: 28px;
  transition: background-color .24s, box-shadow .24s;
}

.button_lost:hover {
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, #cc4d29 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 3px 9px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
}

.button_lost:disabled{
   background:  #fff;
   color: #ccc;
   cursor: no-drop;
}

.button_selected {
  border: 0;
  outline: 0;
  cursor: pointer;
  color: white;
  background-color: #faa541;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, #faa541 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 8px;
  display: inline-block;
  min-height: 28px;
  transition: background-color .24s, box-shadow .24s;
}

.button_selected:hover {
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, #faa541 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 3px 9px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
}