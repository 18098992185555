.statsBannerContainer {
	.orangeText {
		color: #e69f3b !important;
	}

	.boldText {
		font-size: 23px;
		font-weight: 600;
	}

	.italicText {
		font-weight: 600;
		font-style: italic;
	}

	@media screen and (max-width: 1150px) {
		flex-direction: column;
	}
	.bannerColumn {
		background-color: rgb(24, 54, 80);
		display: flex;
		// flex-direction: column;
		align-items: center;

		color: white;
		min-height: 250px;
		text-align: left;
		padding: 0 15px;
		.colContainer {
			font-size: 20px;
		}
		div {
			font-weight: 500;
			line-height: 1.2;
			width: 100%;
			padding: 0 10px;
		}
	}
	.statsBanner {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		width: 75%;
		// gap: 70px;
		// padding: 20px 0px;
		padding-bottom: 0;
		background-color: rgb(24, 54, 80);
		color: white;
		@media screen and (max-width: 800px) {
			width: 100%;
		}
		.statsBannerItem {
			width: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			font-family: "Poppins", sans-serif;
			line-height: 1 !important;
			h3 {
				font-size: 32px;
				font-weight: 700;
			}
			h5 {
				font-size: 12px;
				line-height: 1.2 !important;
			}
			.statsBannerItemContent {
				@media screen and (max-width: 366px) {
					width: min-content;
				}
			}
		}
		@media screen and (max-width: 1200px) {
			.statsBannerItem {
				h3 {
					font-size: 32px;
				}
			}
		}
	}
	:global(.carousel-control-prev) {
		width: 5%;
		z-index: 20;
	}
	:global(.carousel-control-next) {
		width: 5%;
		z-index: 20;
	}
	.sliderColumn {
		.carouselImage {
			max-height: 280px;
			object-fit: cover;
			width: 100%;
		}
		.caption {
			background-color: rgba(0, 0, 0, 0.5);
			top: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			h5 {
				// font-family: "Poppins", sans-serif;
				// font-weight: 700;
				// font-size: 32px;
				text-align: left !important;
				width: 100%;

				// margin-right: -15px;
			}
			.normalLeftText {
				text-align: left;
			}
			ul {
				margin-right: 40px;
				margin-left: 30px;

				width: 100%;
				text-align: left;

				li {
					margin-bottom: 0px;
					font-size: 14px;
					text-align: left;
				}
			}
			@media screen and (max-width: 1384px) {
				h5 {
					font-size: 30px;
				}
				ul {
					font-size: 13px;
				}
			}
			@media screen and (max-width: 1277px) {
				h5 {
					font-size: 28px;
				}
				ul {
					font-size: 12.1px;
				}
			}
			@media screen and (max-width: 1159px) {
				h5 {
					font-size: 27px;
				}
				ul {
					font-size: 11.7px;
				}
			}
			@media screen and (max-width: 1150px) {
				h5 {
					font-size: 32px;
				}
				ul {
					font-size: 14px;
				}
			}
			@media screen and (max-width: 682px) {
				h5 {
					font-size: 30px;
				}
				ul {
					font-size: 13px;
				}
			}
			@media screen and (max-width: 600px) {
				h5 {
					font-size: 28px;
				}
				ul {
					font-size: 12.1px;
				}
			}
			@media screen and (max-width: 569px) {
				h5 {
					font-size: 27px;
				}
				ul {
					font-size: 11.7px;
				}
			}
			@media screen and (max-width: 554px) {
				h5 {
					font-size: 25px;
				}
				ul {
					font-size: 10.9px;
				}
				div[class*="bannerColumn"] {
					font-size: 15px !important;
				}
				div[class*="boldText"] {
					font-size: 15px !important;
				}
			}
			@media screen and (max-width: 513px) {
				ul {
					margin-left: 0px;
					margin-right: 10px;
				}
			}
			@media screen and (max-width: 452px) {
				h5 {
					font-size: 22px;
				}
				ul {
					font-size: 9.6px;
				}
			}
			@media screen and (max-width: 404px) {
				h5 {
					font-size: 20px;
				}
				ul {
					font-size: 8.7px;
				}
			}
			@media screen and (max-width: 368px) {
				h5 {
					font-size: 19px;
				}
				ul {
					font-size: 8.2px;
				}
			}
			@media screen and (max-width: 350px) {
				h5 {
					font-size: 17px;
				}
				ul {
					font-size: 7.3px;
				}
			}
			box-shadow: inset 0 0 0 2000px rgba(24, 54, 80, 0.5);
		}
	}
	.servicesListOuter {
		width: 100%;
		box-shadow: inset 0 0 0 2000px rgba(24, 54, 80, 0.5);
		display: block;
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		padding: 0 50px;
		.servicesList {
			flex: 1;
			padding-top: 45px;
			padding-bottom: 45px;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			gap: 10px;
			.servicesListItem {
				.serviceImageWrapper {
					width: 40px;
					height: 40px;
					min-width: 40px;
					img {
						width: 100%;
						height: 100%;
					}
				}
				align-items: center;
				box-shadow: rgba(0, 0, 0, 0.5) 3px 3px 30px;
				display: flex;
				flex-direction: row;
				gap: 10px;
				border: 1px solid white;
				border-radius: 5px;
				color: black;
				background: white;
				width: 30%;
				font-family: "Poppins", sans-serif;
				padding: 10px;
				font-size: 16px;
				p {
					margin-bottom: 0;
					text-align: left;
				}
				@media screen and (max-width: 1500px) {
					font-size: 13px;
				}
				@media screen and (max-width: 1350px) {
					font-size: 12px;
				}
				@media screen and (max-width: 1275px) {
					font-size: 10px;
				}
				@media screen and (max-width: 1024px) {
					flex-direction: column;
					align-items: flex-start;
					font-size: 8px;
					.serviceImageWrapper {
						width: 20px;
						height: 20px;
						min-width: 20px;
					}
				}
				@media screen and (max-width: 1024px) {
					font-size: 12px;
					.serviceImageWrapper {
						display: none;
					}
					justify-content: center;
					align-items: left;
				}
				@media screen and (max-width: 470px) {
					font-size: 9px;
				}
				@media screen and (max-width: 380px) {
					font-size: 8px;
					padding: 5px;
				}
				@media screen and (max-width: 350px) {
					font-size: 7px;
				}
			}
		}
	}
}
